.circle {
  width: 34px;
  height: 34px;
  line-height: 35px;
  background-color: #d4d4d4;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  font-size: 16px;
  font-weight: bold;
}

.step-wrapper {
  display: flex;
  margin: auto;
  z-index: 1;
}

.step-block .circle-wrapper {
  padding: 0px 20px;
  position: relative;
  width: 90px;
}

.selected .circle {
  color: var(--white);
  background-color: #f7a921;
  border: 1px solid #f7a921;
  font-size: 18px;
  line-height: 33px;
}

.step-block:not(:last-child) > .circle-wrapper::after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: var(--lightBlue);
  margin: auto;
  z-index: -1;
}

.step-block:not(:last-child) > .circle-wrapper::after {
  height: 4px;
}

.step-block:not(:last-child).selected > .circle-wrapper::after,
.step-block:not(:last-child).selected ~ .step-block:not(:last-child) > .circle-wrapper::after {
  opacity: 0.6;
}

.primaryButton {
  padding: 10px 25px;
  background-color: green;
  border: none;
  margin: 10px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.primaryButton:disabled {
  opacity: 0.5;
}
