.nourishment-page-wrapper {
  height: fit-content;
  min-height: 73vh;
  min-width: 50%;
  background-color: var(--white);
  margin: auto;
  margin-top: 2px;
}

.nourishment-page-wrapper h1 {
  margin-top: 50px;
}

.nourishment-page-wrapper .page-description-paragraph {
  margin-top: 45px;
}

.nourishment-page-wrapper .cards-wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  row-gap: 43px;
  column-gap: 12px;
  margin-top: 69px;
  align-items: center;
  justify-content: center;
  width: 711px;
  margin: auto;
  margin-top: 82px;
  margin-bottom: 84px;
}

.cards-wrapper .food-card {
  grid-column: span 4;
  margin: auto;
  position: relative;
  cursor: pointer;
}

.food-card .img-container {
  width: 213px;
  height: 148px;
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 8px 8px 0px 0px;
  margin: auto;
  padding-top: 25px;
}

.food-card .img-container svg {
  height: 100px;
  width: 100px;
}

.food-card:hover .card-image {
  transition: 0.2s ease-in-out;
  opacity: 0.55;
}

.cards-wrapper .card-image {
  width: 138px;
  height: 97.6px;
  margin-top: 26px;
  cursor: pointer;
}

.cards-wrapper .nourishment-page-info-icon {
  opacity: 0;
  position: absolute;
  top: 12px;
  right: 12px;
  color: var(--lightBlue);
  font-size: medium;
}

.cards-wrapper .food-card:hover .nourishment-page-info-icon {
  opacity: 1;
}

.food-card h4 {
  height: 45px;
  width: 213px;
  max-width: 100%;
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000029;
  border-radius: 0px 0px 8px 8px;
  letter-spacing: 0px;
  text-align: center;
  padding-top: 10px;
  margin: auto;
}

.food-card::before {
  content: "";
  background: var(--lightBlue);
  position: absolute;
  width: 213px;
  height: 0;
  transition: 0.2s ease-in-out;
  left: 0;
  bottom: 0;
}

.text-box {
  position: absolute;
  text-align: center;
  top: 73%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.2s ease-in-out;
  opacity: 0;
  width: 101%;
  height: 105px;
  border: 1px solid #7070704b;
  border-radius: 0px 0px 8px 8px;
}

.text-box:hover {
  cursor: pointer;
}

.cards-wrapper .food-card:hover .text-box {
  opacity: 1;
  background-color: var(--lightBlue);
}

.text-box h6 {
  color: var(--white);
  margin-top: 12px;
}

.text-box p {
  font-size: 12px;
  color: var(--white);
  width: 167px;
  height: 47px;
  margin: auto;
  margin-top: 5px;
  max-width: 100%;
}

.nourishment-page-selected-card .selected-nournishment-card {
  height: 193px;
  width: 213px;
  max-width: 100%;
  padding-top: 40%;
  background-color: var(--lightBlue);
  border-radius: 8px;
  color: var(--white);
}

.selected-nournishment-card .nourishment-page-card-circle-done-icon {
  position: absolute;
  top: 12.75px;
  left: 12.75px;
}

.nourishment-page-wrapper .mobile-information-button-nourishment-page {
  padding: 0.2em 1.5em;
  margin-top: 50px;
  background-color: var(--white);
  color: var(--lightBlue);
  font-weight: 600;
  border: 1px solid var(--lightBlue);
  border-radius: 0;
  cursor: pointer;
  display: none;
}

@media (hover: none) {
  .nourishment-page-wrapper .mobile-information-button-nourishment-page {
    display: flex;
    margin-inline: auto;
    text-transform: none;
  }

  .mobile-version-text-box {
    opacity: 1;
    background-color: var(--lightBlue);
    transition: 250ms ease-in-out;
  }
}

@media only screen and (max-width: 899px) {
  .nourishment-page-wrapper .page-description-paragraph {
    width: 80%;
    margin-top: 20px;
  }

  .nourishment-page-wrapper .cards-wrapper {
    grid-template-columns: repeat(3, 1fr);
    width: 80%;
    row-gap: 25px;
    column-gap: 0;
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .cards-wrapper .food-card {
    grid-column: span 1;
  }

  .nourishment-page-selected-card .selected-nournishment-card {
    height: 120px;
    width: 120px;
  }

  .food-card .img-container {
    width: 120px;
    height: 85px;
    box-shadow: 0px 2px 5px #00000029;
  }

  .food-card .img-container svg {
    width: 60px;
    height: 60px;
  }

  .food-card h4 {
    width: 120px;
    height: 35px;
    font-size: 16px;
  }

  .text-box {
    height: 75px;
  }

  .text-box h6 {
    font-size: 12px;
    margin-top: 5px;
  }

  .text-box p {
    width: 110px;
    height: 40px;
    font-size: 10px;
    line-height: 1;
  }
}

@media only screen and (max-width: 600px) {
  .nourishment-page-wrapper .cards-wrapper {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .food-options-page-wrapper .food-options-page-description-paragraph {
    width: 80%;
  }
}

@media only screen and (max-width: 290px) {
  .nourishment-page-wrapper .cards-wrapper {
    column-gap: 12px;
    row-gap: 15px;
  }
}
