.footer {
  height: 115px;
  width: 100%;
  margin: auto;
  bottom: 0;
  right: 0;
  border-radius: 1px;
  border-top: 1px solid rgba(112, 112, 112, 0.1);
  background-color: var(--white);
}

.footer button {
  background-color: var(--lightOrange);
  color: #01140d;
  box-shadow: 0px 3px 6px #00000029;
  width: 289px;
  height: 58px;
  border: 1.5px solid white;
  border-radius: 50px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin: 28px auto;
  text-transform: none;
}

.footer Button:hover {
  background-color: var(--lightOrange);
}

.footer .button-arrow {
  display: absolute;
  bottom: 0;
}

.footer .footer-error-handler {
  opacity: 1;
}

@media only screen and (max-width: 329.9px) {
  .footer Button {
    width: 200px;
    margin-inline: auto;
    font-size: 14px;
    line-height: 1;
  }
}
