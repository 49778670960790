.order-book-page-wrapper {
  min-height: 100vh;
  margin-top: 2px;
  width: 100%;
  margin-inline: auto;
}

.order-book-page-wrapper .order-book-secondary-paragraph {
  margin-top: 50px;
}

.order-book-page-wrapper .order-book-main-title {
  margin-top: 50px;
}

.order-book-page-wrapper .order-book-main-paragraph {
  color: #707070;
  font-weight: 400;
  margin-top: 30px;
  max-width: 450px;
}

.main-plate-image-as-background-div {
  transform: rotate(-20deg);
  background-image: url("../../Assets/Images/Grafiken/ohslimteller.png");
  background-repeat: no-repeat, repeat;
  height: 650px;
  width: 650px;
  opacity: 0.5;
  background-position: -200px;
  background-size: 600px;
  position: absolute;
  top: 250px;
  left: -150px;
  z-index: -10;
}

.main-book-image {
  max-width: 400px;
  max-height: 450px;
  margin-left: 10px;
  filter: drop-shadow(2px 10px 5px #00000029);
}

.personalise-book-container {
  display: flex;
  justify-content: space-around;
  margin-top: 120px;
  width: 60%;
  margin-inline: auto;
}

.personalise-book-container .personalize-book-texts-container {
  width: 450px;
  text-align: left;
  margin-right: 10px;
}

.personalize-book-texts-container .personalise-title {
  margin-inline: 0;
}

.personalize-book-texts-container .personalise-paragraph {
  margin-top: 20px;
  margin-inline: 0;
  max-width: 92%;
}

.order-book-page-inputs {
  display: flex;
  flex-direction: column;
  width: 275px;
  margin-top: 40px;
}

.order-book-page-inputs input {
  border: 1px solid var(--lightBlue);
  border-radius: 50px;
  height: 56px;
  margin-bottom: 24px;
  padding: 24px;
  font-size: 16px;
}

.order-now-section {
  margin-inline: auto;
  margin-top: 130px;
  position: relative;
}

.order-now-section h2 {
  font-size: 52px;
}

.order-now-section .order-now-paragraph {
  margin-top: 40px;
  margin-bottom: 20px;
  width: 600px;
}

.images-and-shape-container {
  margin-top: -750px;
}

.images-and-card-container {
  padding-left: 100px;
  display: flex;
  width: 50%;
  margin-inline: auto;
  justify-content: space-around;
  position: relative;
  top: 10vh;
}

.plate-image-as-background-div {
  background-image: url("../../Assets/Images/Grafiken/ohslimteller.png");
  background-repeat: no-repeat, repeat;
  height: 600px;
  opacity: 0.6;
  background-position: 85vw;
  background-size: 590px;
}

.images-and-card-container .order-now-section-book-img {
  height: 550px;
  width: 600px;
  margin-top: auto;
  z-index: 1;
  filter: drop-shadow(2px 10px 5px #00000029);
  transform: translate(-10px, 30px);
}

.triangle-shape {
  width: 60%;
  height: 0px;
  border-top: 30vh solid transparent;
  border-left: 70vw solid var(--lightBlue);
  border-bottom: 0px solid transparent;
  margin: 0px auto 100px auto;
  z-index: 0;
}

.order-book-card-container {
  position: relative;
  background-color: white;
  width: 240px;
  min-width: 240px;
  height: 210px;
  min-height: 210px;
  box-shadow: 0px 10px 20px rgba(134, 134, 134, 0.795);
  transform: translate(-20%, 10%);
  overflow: hidden;
  border-radius: 3px;
  margin: 10px;
}

.card-price {
  font-weight: bold;
  font-size: 1rem;
  margin-top: -8px;
}

.order-book-card-container .card-title {
  color: var(--lightBlue);
  font-size: 1.3rem;
  margin-top: 20px;
  font-weight: lighter;
}

.card-title .bold-title {
  color: var(--lightBlue);
  font-size: 1.4rem;
  font-weight: bold;
}

.order-book-advantages-list .delivery-time-message {
  font-size: 0.75rem;
  margin-top: 10px;
  max-width: 80%;
  height: 40px;
}

.order-book-card-container .price-text-euro {
  color: var(--lightBlue);
  font-size: 2.5rem;
}

.order-book-card-container .price-text-euro-sign {
  font-size: 1.1rem;
}

.order-book-card-container .order-button {
  background-color: var(--lightOrange);
  color: white;
  text-transform: capitalize;
  height: 28px;
  width: 140px;
  font-weight: bold;
  font-size: 0.9rem;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgb(168, 168, 168);
  line-height: 1;
}

.order-book-card-container .order-button:hover {
  background-color: var(--lightBlue);
}

.order-button .order-text {
  text-transform: lowercase;
  display: block;
  font-weight: normal;
  font-size: 0.9rem;
  margin: 5px;
}

/* LAST PAGE */

.order-successfully-completed {
  font-size: 20px;
  color: var(--lightBlue);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-inline: auto;
  max-width: 60%;
  
}

.thanks-for-order {
  font-size: 26px;
  margin-bottom: 50px;
}

.your-team {
  font-size: 26px;
  margin-top: 50px;
}

h3 {
  margin-bottom: 10px;
  font-size: 15px;
  text-align: center;
}

.bold-text {
  font-weight: bold;
}


.contact-us{
  text-decoration: none;
  color: var(--lightOrange);
}

@media only screen and (max-width: 1450px) {
  .main-plate-image-as-background-div {
    background-size: 500px;
    background-position: -150px;
    top: 280px;
  }

  .personalise-book-container {
    justify-content: space-between;
    width: 65%;
    padding-left: 5%;
  }

  .plate-image-as-background-div {
    background-size: 500px;
  }

  .triangle-shape {
    width: 90%;
    border-left: 90vw solid var(--lightBlue);
  }
}

@media only screen and (max-width: 1200px) {
  .main-plate-image-as-background-div {
    background-size: 400px;
    background-position: -80px;
    top: 300px;
  }

  .plate-image-as-background-div {
    background-size: 400px;
  }

  .main-book-image {
    max-width: 360px;
    max-height: 400px;
  }
}
@media only screen and (max-width: 1050px) {
  .personalise-book-container .personalize-book-texts-container {
    margin-top: 0;
  }

  .order-book-page-inputs {
    width: 250px;
  }

  .order-book-page-inputs input {
    height: 50px;
  }

  .main-book-image {
    max-width: 360px;
    max-height: 400px;
  }

  .images-and-card-container .order-now-section-book-img {
    height: 350px;
    width: 350px;
  }
}

@media only screen and (max-width: 850px) {
  .main-plate-image-as-background-div {
    background-size: 300px;
    background-position: -50px;
    top: 250px;
  }

  .plate-image-as-background-div {
    background-size: 300px;
  }

  .personalise-book-container {
    width: 60%;
  }

  .personalise-book-container .personalize-book-texts-container {
    margin-top: -50px;
  }

  .order-now-section h2 {
    font-size: 32px;
  }

  .main-book-image {
    max-width: 240px;
    max-height: 300px;
  }
}

@media only screen and (max-width: 700px) {
  .main-plate-image-as-background-div {
    transform: none;
    width: 100%;
    background-size: 150px;
    background-position: 80px;
    top: 300px;
  }

  .personalise-book-container {
    width: 60%;
    margin-inline: auto;
    flex-direction: column;
  }

  .personalise-book-container .personalize-book-texts-container {
    margin-top: 0px;
    text-align: center;
    width: 100%;
  }

  .order-book-page-inputs {
    width: 95%;
  }

  .main-book-image {
    display: none;
  }

  .order-now-section {
    margin-top: 50px;
  }

  .plate-image-as-background-div {
    background-size: 200px;
  }

  .triangle-shape {
    margin-bottom: 20px;
  }
  .images-and-card-container {
    flex-direction: column;
    margin-inline: auto;
  }
}

@media only screen and (max-width: 500px) {
  .images-and-card-container .order-now-section-book-img {
    width: 200px;
    height: 200px;
  }
  .images-and-card-container {
    position: relative;
  }
}
