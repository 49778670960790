@import url("./Assets/Styles/steps.css");
@import url("./Assets/Styles/navbar.css");
@import url("./Pages/Home/home.css");
@import url("./Pages/UserInformation/userInformationPage.css");
@import url("./Assets/Styles/footer.css");
@import url("./Assets/Styles/sliders.css");
@import url("./Pages/Nourishment/nourishmentPage.css");
@import url("./Pages/FoodOptions/foodOptions.css");
@import url("./Pages/DailyLife/dailyLife.css");
@import url("./Pages/OrderBook/orderBook.css");
@import url("./Assets/Styles/alerts.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html {
  height: 100vh;
}

#root {
  width: 100%;
  height: 100%;
}

:root {
  --white: #ffffff;
  --lightBlue: #64a0cc;
  --lightOrange: #f7a921;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-family: "Helvetica Neue", sans-serif;
  text-align: center;
  background: #fbfefe;
}

.App {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.spinner-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

fieldset {
  border: none !important;
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

@font-face {
  font-family: "Hind";
  font-style: normal;
  font-weight: 300 400 500 600 700;
  src: url("../src/Assets/Fonts/Hind-Light.ttf") format("truetype"),
    url("../src/Assets/Fonts/Hind-Regular.ttf") format("truetype"),
    url("../src/Assets/Fonts/Hind-Medium.ttf") format("truetype"),
    url("../src/Assets/Fonts/Hind-SemiBold.ttf") format("truetype"),
    url("../src/Assets/Fonts/Hind-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../src/Assets/Fonts/Roboto-Bold.ttf") format("truetype");
}
