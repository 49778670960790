.error-message-no-display {
  display: none;
}

.display-error-message {
  display: inline;
}

.display-error-message p {
  color: #d32f2f;
  display: inline;
  font-size: 0.75rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
}

.alert-container .alert-stack {
  border-radius: 20px;
  width: 100%;
  position: absolute;
  top: 138px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.alert-container .alert-font-size {
  font-size: 14px;
  max-height: fit-content;
  font-weight: 600;
}

.display-error-message .error-message-display-block-mt-10 {
  display: block;
  margin-top: 10px;
}

.display-error-message .nourishment-error-message {
  display: block;
  padding-top: 20px;
  margin-bottom: -40px;
}

.display-error-message .food-options-error-message {
  display: block;
  padding-top: 50px;
  margin-bottom: -50px;
}

.display-error-message .order-book-error-message {
  display: block;
  margin-top: -20px;
  margin-bottom: 10px;
  padding-left: 24px;
}

@media only screen and (max-width: 500px) {
  .alert-container .alert-font-size {
    font-size: 10px;
    font-weight: 400;
  }
}
