.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 112px;
  box-shadow: 0px 1px 2px #00000029;
  background-color: var(--white);
}

.navbar Button {
  border: none;
  background: none;
  color: var(--lightBlue);
  font-weight: 600;
  font-size: 18px;
  width: 20%;
  cursor: pointer;
  text-transform: none;
}

.navbar Button:hover {
  background-color: transparent;
}

.navbar .navbar-image-container {
  width: 19%;
}

.navbar-logo {
  max-width: 202px;
  height: 63px;
  margin: auto;
}

.no-display {
  display: none;
}

.step-block .circle-wrapper .circle .navbar-done-icon {
  font-size: 22px;
  margin-top: 5px;
  stroke-width: 1;
}

@media only screen and (max-width: 1100px) {
  .step-block .circle-wrapper {
    width: 50px;
    padding: 0px 8px;
  }
  .navbar Button {
    display: none;
  }
  .navbar {
    flex-direction: column-reverse;
  }
  .navbar .navbar-image-container {
    width: 100%;
    margin: auto;
  }
}

@media only screen and (max-width: 450px) {
  .step-block .circle-wrapper {
    width: 50px;
    padding: 0px 8px;
  }

  .step-block .circle-wrapper .circle {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
  }

  .step-block .circle-wrapper .circle .navbar-done-icon {
    font-size: 20px;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 348px) {
  .navbar {
    height: 110px;
  }
  .step-block .circle-wrapper {
    width: 35px;
    padding: 0px 8px;
  }

  .step-block .circle-wrapper .circle {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
  }

  .step-block .circle-wrapper .circle .navbar-done-icon {
    font-size: 20px;
    margin-top: 5px;
  }
}
